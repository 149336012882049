import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import CheckList from '../../CheckList/CheckList';
import CheckListItem from '../../CheckList/CheckListItem';

const BodyTypeStep = () => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const [options, setOptions] = useState([
    { name: 'City Car', disabled: !query.filteredBodyType.includes('City Car') },
    { name: 'Convertible', disabled: !query.filteredBodyType.includes('Convertible') },
    { name: 'Coupe', disabled: !query.filteredBodyType.includes('Coupe') },
    { name: 'Estate', disabled: !query.filteredBodyType.includes('Estate') },
    { name: 'Hatchback', disabled: !query.filteredBodyType.includes('Hatchback') },
    { name: 'MPV & 7 Seater', disabled: !query.filteredBodyType.includes('MPV & 7 Seater') },
    { name: 'Performance', disabled: !query.filteredBodyType.includes('Performance') },
    { name: 'Pickup', disabled: !query.filteredBodyType.includes('Pickup') },
    { name: 'Prestige', disabled: !query.filteredBodyType.includes('Prestige') },
    { name: 'Saloon', disabled: !query.filteredBodyType.includes('Saloon') },
    { name: 'SUV', disabled: !query.filteredBodyType.includes('SUV') },
  ]);

  const updateOptions = () => {
    setOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        disabled: !query.filteredBodyType.includes(option.name),
        checked: query.bodyTypeCategories.includes(option.name),
      })),
    );
  };

  useEffect(() => {
    updateOptions();
  }, [query]);

  // Check if value exists in bodyType array state
  const checkValueExists = (val) => {
    return query.bodyTypeCategories.some((item) => item === val);
  };

  const handleClick = (event, name) => {
    // Update body type global state to selected options
    let updatedSelections = [...query.bodyTypeCategories];

    // If checking & value doesn't already exist
    if (event.target.value === 'false' && !checkValueExists(name)) {
      updatedSelections.push(name);

      // If unchecking & value already exists
    } else if (event.target.value === 'true' && checkValueExists(name)) {
      const filteredArray = updatedSelections.filter((item) => item !== name);
      updatedSelections = filteredArray;
    }

    dispatch({
      type: 'SET_BODY_TYPE_CATEGORIES',
      payload: updatedSelections,
    });
  };
  return (
    <CheckList>
      {options &&
        options.map((option, i) => (
          <CheckListItem
            id={`${i}`}
            onClick={(event) => handleClick(event, option.name)}
            key={option.name}
            name={option.name}
            disabled={option.disabled}
            is_checked={option.checked}
          />
        ))}
    </CheckList>
  );
};

export default BodyTypeStep;
