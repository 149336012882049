import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Component Imports
import ButtonList from '../../ButtonList/ButtonList';

// Helper Imports
import { fuelMapping, transmissionMapping } from '../utils';

const TransmissionStep = ({ onNext }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const transmissionOptions = [
    {
      text: 'Manual',
      active: query.transmission === 'Manual',
      disabled: !query.filteredTransmission.includes('Manual'),
    },
    {
      text: 'Auto/Semi',
      active: query.transmission === 'Auto/Semi',
      disabled: !query.filteredTransmission.includes('Automatic'),
    },
    {
      text: 'Any',
      active: query.transmission === 'Any',
      disabled: query.filteredTransmission.length !== 2,
    },
  ];

  const [options, setOptions] = useState(transmissionOptions);

  const updateOptions = () => {
    setOptions(transmissionOptions);
  };

  useEffect(() => {
    updateOptions();
  }, [query]);

  const filterNextStep = (data) => {
    const bodyTypes = [];

    data.forEach((item) => {
      if (!bodyTypes.includes(item.bodytype_categories)) {
        bodyTypes.push(item.bodytype_categories);
      }
    });

    dispatch({ type: 'SET_FILTERED_BODYTYPE', payload: bodyTypes });
  };

  const handleClick = (e) => {
    e.preventDefault();

    const selectedTransmission = e.target.innerText;

    axios({
      url: 'find-your-perfect-deal',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      params: {
        transmission: transmissionMapping[selectedTransmission],
        display_fueltype: fuelMapping[query.fuel],
        term: query.term,
        type: query.type,
        mileage: query.mileage,
        price_from: query.minPayment,
        price_to: query.maxPayment,
      },
    }).then((response) => {
      filterNextStep(response.data.results);

      dispatch({ type: 'SET_TRANSMISSION', payload: selectedTransmission });

      onNext();
    });
  };

  return <ButtonList options={options} onClick={handleClick} />;
};

TransmissionStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default TransmissionStep;
