import React from 'react';

// Redux
import { Provider } from 'react-redux';
import store from '../../store/store';

// Component Imports
import StepTracker from './StepTracker';

const FindYourPerfectCar = () => {
  return (
    <Provider store={store}>
      <StepTracker />
    </Provider>
  );
};

export default FindYourPerfectCar;
