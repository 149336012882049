import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const OvoPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.promotion_name && (
      <Pill
        category="promotion_name"
        label="EV Miles Offer"
        value={selectedFilters.promotion_name === 'OVO Promotion'}
      />
    )
  );
};

OvoPills.propTypes = {
  selectedFilters: PropTypes.shape({
    promotion_name: PropTypes.string,
  }).isRequired,
};

export default OvoPills;
