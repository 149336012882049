import React from 'react';

// Component Imports
import InputSpinner from '../../InputSpinner/InputSpinner';

const BudgetStep = () => {
  return (
    <>
      <InputSpinner
        labelText="Minimum"
        min={0}
        max={300}
        initialValue={100}
        step={50}
        id="min-monthly-payment"
        updateValue="SET_MIN_PAYMENT"
      />

      <InputSpinner
        labelText="Maximum"
        min={250}
        max={1000}
        initialValue={350}
        step={50}
        id="max-monthly-payment"
        updateValue="SET_MAX_PAYMENT"
      />
    </>
  );
};

export default BudgetStep;
