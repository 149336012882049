import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Helpers
import { fuelMapping, transmissionMapping, getObjKey } from './utils';

const StepTrackerPagination = ({ pages, jumpToStep }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const mappings = {
    fuel: fuelMapping,
    transmission: transmissionMapping,
  };

  const resetDataFromQuery = (data, type, step) => {
    const updatedData = { data: [], step };

    data.forEach((item) => {
      if (!updatedData.data.includes(item[`${type}`])) {
        updatedData.data.push(item[`${type}`]);
      }
    });

    type = type === 'display_fueltype' ? 'fuel' : type;

    updatedData.data.forEach((element) => {
      getObjKey(mappings[`${type}`], element);
    });

    dispatch({ type: 'RESET_STEP_TRACKER', payload: updatedData });
  };

  const resetQuery = (type, step) => {
    axios({
      url: 'find-your-perfect-deal',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      params: {
        display_fueltype: type === 'transmission' ? fuelMapping[query.fuel] : '',
        transmission: '',
        term: query.term,
        type: query.type,
        mileage: query.mileage,
        price_from: query.minPayment,
        price_to: query.maxPayment,
      },
    }).then((response) => {
      resetDataFromQuery(response.data.results, type, step);
    });
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    const clickedStep = index + 1;

    // The below two variables correspond to the steps outlined in the FMPC steptracker.jsx
    const fuelTypeStep = 5;
    const transmissionTypeStep = 6;

    if (clickedStep === fuelTypeStep) {
      resetQuery('display_fueltype', clickedStep);
    } else if (clickedStep === transmissionTypeStep) {
      resetQuery('transmission', clickedStep);
    } else {
      dispatch({ type: 'RESET_STEP_TRACKER', payload: { step: clickedStep } });
    }

    dispatch({ type: 'SET_FMPC_CURRENT_STEP', payload: clickedStep });

    jumpToStep(index);
  };

  return (
    <div className="step-tracker__pagination">
      <ol className="step-tracker__progress">
        {pages &&
          pages.map((item, index) => {
            let stepStatus;
            if (query.currentStep - 1 === index) {
              stepStatus = 'doing';
            } else if (query.currentStep - 1 > index) {
              stepStatus = 'done';
            } else {
              stepStatus = 'todo';
            }

            return (
              <li
                className={`step-tracker__progress-item step-tracker__progress-item--${stepStatus}`}
                value={index}
                key={item.name}
              >
                {query.currentStep - 1 > index ? (
                  <a className="step-tracker__progress-link" href="/" onClick={(e) => handleClick(e, index)}>
                    {item.name}
                  </a>
                ) : (
                  <span>{item.name}</span>
                )}
              </li>
            );
          })}
      </ol>
    </div>
  );
};

StepTrackerPagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  jumpToStep: PropTypes.func.isRequired,
};

StepTrackerPagination.defaultProps = {
  pages: [],
};

export default StepTrackerPagination;
