import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const InitialPayment = ({ name, options, isDesktop, elasticSearchProps }) => {
  options = options.map((option) => {
    let label = `${parseFloat(option.value).toLocaleString('en-GB')} month`;

    if (option.value !== '1') {
      label = label.concat('s');
    }

    return { ...option, label };
  });

  options.sort((a, b) => a.value - b.value);

  const selected =
    elasticSearchProps.selectedFilters[name] &&
    options.find((option) => option.value === elasticSearchProps.selectedFilters[name]);
  const count = selected ? selected.label : '';

  return (
    <FilterDrawerItem
      title="Initial payment"
      allText="Any"
      count={count}
      name={name}
      options={options}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

InitialPayment.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

InitialPayment.defaultProps = {
  options: [],
  isDesktop: false,
};

export default InitialPayment;
