import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollSnapSlider } from '../scrollSnapSlider';

import Pills from './SpecialOffersFilters/filterPills/Pills';
import { shouldShowFeature } from '../../splitTesting';

import SortByDropdown from './SortByDropdown';
import EstimatedDeliveryDropdown from './EstimatedDeliveryDropdown';
import InStockToggle from './InStockToggle';
import OVOToggle from './OVOToggle';
import FundersDropdown from './FundersDropdown';
import ArrowLeftIcon from '../../../assets/images/arrow-left.svg';
import ArrowRightIcon from '../../../assets/images/arrow-right.svg';
import { useElasticSearch } from './ElasticSearch';

const FilterControls = ({
  labelMapping,
  isAdmin,
  path,
  controller,
  action,
  isOnlySortVisible,
  fundersVisible,
  isVans,
  className,
}) => {
  const pillsRef = useRef();
  const arrowLeftRef = useRef();
  const arrowRightRef = useRef();

  const { hasAnyOption } = useElasticSearch();

  const { isOpen, isFixed, filters, theme, totalCounts } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [isOverflown, setIsOverflown] = useState(false);

  const clearAll = (event) => {
    const form = event.target.closest('form');

    dispatch({ type: 'RESET_FILTERS' });

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  const handleOpen = () => {
    dispatch({ type: 'SET_OPEN', payload: !isOpen });
  };

  useEffect(() => {
    const filterControlsForm = document.querySelector('.filter-controls');
    const filterWrapper = document.querySelector('.drv-filter-desktop');
    const filterWrapperRect = filterWrapper.getBoundingClientRect() || 0;

    if (isOpen && isFixed) {
      filterControlsForm.style.top = `${filterWrapperRect.height + 48}px`;
    } else if (controller === 'specials') {
      filterControlsForm.style.top = `${Math.max(filterWrapperRect.height, 125) + 32}px`;
    } else {
      filterControlsForm.style.top = '48px';
    }
  }, [isOpen, isFixed]);

  useEffect(() => {
    setFilterList(Object.keys(filters));
  }, [filters]);

  useEffect(() => {
    setIsOverflown(pillsRef.current.scrollWidth > pillsRef.current.clientWidth);
  }, [filters, isFixed]);

  useEffect(() => {
    const filtersWithoutDefaults = filters;
    delete filtersWithoutDefaults.customer_type;
    delete filtersWithoutDefaults.sort_by;

    if (Object.keys(filtersWithoutDefaults).length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [filters, filterList.map((name) => `filters[${name}]`)]);

  useEffect(() => {
    // eslint-disable-next-line no-new
    new ScrollSnapSlider(pillsRef.current, arrowLeftRef.current, arrowRightRef.current);
  }, []);

  const showEstimatedDelivery = isAdmin && controller === 'specials' && !isOnlySortVisible;
  const showFunders = isAdmin && fundersVisible;

  const showInStock = shouldShowFeature('stock') && hasAnyOption('stock', 'true');

  const showOvo = !isVans && hasAnyOption('promotion_name', 'OVO Promotion');

  return (
    <div
      className={classnames('drv-filter-controls__desktop', `${className}`, {
        [`drv-filter-controls--${theme}`]: theme,
        'drv-filter-controls__desktop--fixed': isFixed,
      })}
    >
      {!isOnlySortVisible && (
        <button className="drv-filter-controls__expand drv-button drv-button--blue" type="button" onClick={handleOpen}>
          {isOpen ? 'Hide' : 'Expand'} Filters
        </button>
      )}
      <div className="pills-group">
        <span className="results-count">{totalCounts.count} results</span>
        <div className={`indicators prev ${!isOverflown || isFixed ? 'hidden' : ''}`}>
          <button ref={arrowLeftRef} type="button" className="arrow prev disabled">
            <img src={ArrowLeftIcon} alt="" srcSet="" />
          </button>
        </div>
        <div ref={pillsRef} data-ga-filters className="drv-pills drv-pills-slider">
          <Pills selectedFilters={filters} labelMapping={labelMapping} />
        </div>
        <div className={`indicators next ${!isOverflown || isFixed ? 'hidden' : ''}`}>
          <button ref={arrowRightRef} type="button" className="arrow next">
            <img src={ArrowRightIcon} alt="" srcSet="" />
          </button>
        </div>
        {!isEmpty && (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              className={classnames('drv-filter-controls__clear drv-link', {
                [`drv-link--${theme}`]: theme,
                'drv-link--gold': !theme,
              })}
              data-action="click->ga-filters#push"
              data-ga-filters-event-action="cleared_all_filters"
              onClick={clearAll}
            >
              Clear all
            </span>
          </>
        )}
        {controller === 'specials' && action !== 'used' && (
          <div style={{ marginLeft: '50px', display: 'flex' }}>
            {!isAdmin && showInStock && <InStockToggle path={path} />}
            {showOvo && <OVOToggle path={path} />}
          </div>
        )}
      </div>
      {showFunders && <FundersDropdown path={path} />}
      {showEstimatedDelivery && <EstimatedDeliveryDropdown path={path} />}
      <SortByDropdown controller={controller} action={action} />
    </div>
  );
};

FilterControls.propTypes = {
  className: PropTypes.string,
  labelMapping: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool,
  path: PropTypes.string.isRequired,
  controller: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isOnlySortVisible: PropTypes.bool,
  fundersVisible: PropTypes.bool,
  isVans: PropTypes.bool,
};

FilterControls.defaultProps = {
  className: '',
  isAdmin: false,
  isOnlySortVisible: false,
  fundersVisible: false,
  isVans: false,
};

export default FilterControls;
