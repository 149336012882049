import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import ButtonList from '../../ButtonList/ButtonList';

const TermStep = ({ onNext }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const [options] = useState([
    { text: '24', active: query.term === '24' },
    { text: '36', active: query.term === '36' },
    { text: '48', active: query.term === '48' },
    { text: 'Any', active: query.term === 'Any' },
  ]);

  const handleClick = (e) => {
    e.preventDefault();

    dispatch({
      type: 'SET_TERM',
      payload: e.target.innerText,
    });

    onNext();
  };

  return <ButtonList options={options} onClick={handleClick} />;
};

TermStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default TermStep;
