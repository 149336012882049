import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Helpers
import { fuelMapping, transmissionMapping } from './utils';

const StepTrackerControls = ({ isLast, step, backDisabled, nextDisabled, nextClicked, backClicked }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const handleEnterPress = (event, fn) => {
    if (event.which === 13) {
      fn();
    }
  };

  const handleNextClick = () => {
    if (isLast) {
      axios({
        url: 'find-your-perfect-deal',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: {
          transmission: transmissionMapping[query.transmission],
          display_fueltype: fuelMapping[query.fuel],
          term: query.term,
          type: query.type,
          mileage: query.mileage,
          bodytype_categories: query.bodyTypeCategories,
          price_from: query.minPayment,
          price_to: query.maxPayment,
          render_car_cards: true,
        },
      }).then((response) => {
        const parentElement = document.querySelector('.g-perfect-deals');
        console.log(response.data.html);
        parentElement.removeChild(parentElement.querySelector('.l-container-mark'));
        parentElement.innerHTML = response.data.html;
        parentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

        window.history.pushState({ turbolinks: {} }, '', response.request.responseURL);
      });
    } else {
      // Budget submit
      const filterNextStep = (data) => {
        const fuelTypes = [];

        data.forEach((item) => {
          if (!fuelTypes.includes(item.display_fueltype)) {
            fuelTypes.push(item.display_fueltype);
          }
        });

        dispatch({ type: 'SET_FILTERED_FUELTYPES', payload: fuelTypes });
      };

      axios({
        url: 'find-your-perfect-deal',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: {
          term: query.term,
          type: query.type,
          mileage: query.mileage,
          price_from: query.minPayment,
          price_to: query.maxPayment,
        },
      }).then((response) => {
        filterNextStep(response.data.results);
        nextClicked();
      });
    }
  };

  const handleBackClick = () => {
    dispatch({
      type: 'RESET_STEP_TRACKER',
      payload: step,
    });

    backClicked();
  };

  return (
    <div className="step-tracker__controls">
      <button
        className="step-tracker__button"
        onKeyDown={(evt) => handleEnterPress(evt, backClicked)}
        onClick={handleBackClick}
        disabled={step === 0 || backDisabled}
      >
        Back
      </button>

      {!nextDisabled && (
        <button
          className="step-tracker__button step-tracker__button--next"
          onKeyDown={(evt) => handleEnterPress(evt, nextClicked)}
          onClick={handleNextClick}
          disabled={isLast && query.filteredBodyType.length === 0}
        >
          {isLast ? 'Search Cars' : 'Next'}
        </button>
      )}
    </div>
  );
};

StepTrackerControls.propTypes = {
  isLast: PropTypes.bool,
  step: PropTypes.number.isRequired,
  backClicked: PropTypes.func.isRequired,
  nextClicked: PropTypes.func.isRequired,
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
};

StepTrackerControls.defaultProps = {
  isLast: false,
  backDisabled: false,
  nextDisabled: false,
};

export default StepTrackerControls;
