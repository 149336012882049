export const fuelMapping = {
  Petrol: 'Petrol',
  Diesel: 'Diesel',
  'Electric/Hybrid': ['Electric', 'Hybrid', 'Plug-in Hybrid (Petrol)', 'Other'],
  Any: '',
};

export const transmissionMapping = {
  'Auto/Semi': 'Automatic',
  Manual: 'Manual',
  Any: '',
};

export const getObjKey = (obj, val) => {
  const keyArr = [];

  if (val === '') {
    return 'Any';
  }
  for (const keys in obj) {
    if (obj[keys].includes(val)) {
      keyArr.push(keys);
    }
  }
  return keyArr[0];
};

export const renameTypeKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
