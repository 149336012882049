import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Component Imports
import ButtonList from '../../ButtonList/ButtonList';

// Helper Imports
import { fuelMapping } from '../utils';

const FuelStep = ({ onNext }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const anyAlternativeFuelResults = (q) => {
    return (
      q.filteredFuelTypes.includes('Electric') ||
      q.filteredFuelTypes.includes('Hybrid') ||
      q.filteredFuelTypes.includes('Plug-in Hybrid (Petrol)')
    );
  };

  const fuelOptions = [
    {
      text: 'Petrol',
      active: query.fuel === 'Petrol',
      disabled: !query.filteredFuelTypes.includes('Petrol'),
    },
    {
      text: 'Diesel',
      active: query.fuel === 'Diesel',
      disabled: !query.filteredFuelTypes.includes('Diesel'),
    },
    {
      text: 'Electric/Hybrid',
      active: query.fuel === 'Electric/Hybrid',
      disabled: !anyAlternativeFuelResults(query),
    },
    {
      text: 'Any',
      active: query.fuel === 'Any',
      disabled: false,
    },
  ];

  const [options, setOptions] = useState(fuelOptions);

  const updateOptions = () => {
    setOptions(fuelOptions);
  };

  useEffect(() => {
    updateOptions();
  }, [query]);

  const filterNextStep = (data) => {
    const transmissionTypes = [];

    data.forEach((item) => {
      if (!transmissionTypes.includes(item.transmission)) {
        transmissionTypes.push(item.transmission);
      }
    });

    dispatch({ type: 'SET_FILTERED_TRANSMISSION', payload: transmissionTypes });
  };

  const handleClick = (e) => {
    e.preventDefault();
    const selectedFuelType = e.target.innerText;

    axios({
      url: 'find-your-perfect-deal',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      params: {
        display_fueltype: fuelMapping[selectedFuelType],
        term: query.term,
        type: query.type,
        mileage: query.mileage,
        price_from: query.minPayment,
        price_to: query.maxPayment,
      },
    }).then((response) => {
      filterNextStep(response.data.results);

      dispatch({ type: 'SET_FUEL', payload: selectedFuelType });

      onNext();
    });
  };

  return <ButtonList options={options} onClick={handleClick} />;
};

FuelStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default FuelStep;
