import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import ButtonList from '../../ButtonList/ButtonList';

const TypeStep = ({ onNext }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const [options] = useState([
    { text: 'Personal', active: query.type === 'Personal' },
    { text: 'Business', active: query.type === 'Business' },
  ]);

  const togglePricing = (e) => {
    if (e.target.innerText === 'Personal') {
      document.body.classList.remove('is-business');
    } else if (e.target.innerText === 'Business') {
      document.body.classList.add('is-business');
    }

    if (document.body.classList.contains('is-business')) {
      document.cookie = 'pricing_type=is-business;path=/';
    } else {
      document.cookie = 'pricing_type=is-personal;path=/';
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    dispatch({
      type: 'SET_TYPE',
      payload: e.target.innerText,
    });

    togglePricing(e);

    onNext();
  };

  return <ButtonList options={options} onClick={handleClick} />;
};

TypeStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default TypeStep;
