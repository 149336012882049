import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import ButtonList from '../../ButtonList/ButtonList';

const MileageStep = ({ onNext }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const [options] = useState([
    { text: '5000', active: query.mileage === '5000' },
    { text: '8000', active: query.mileage === '8000' },
    { text: '10000', active: query.mileage === '10000' },
    { text: '15000', active: query.mileage === '15000' },
  ]);

  const handleClick = (e) => {
    e.preventDefault();

    dispatch({
      type: 'SET_MILEAGE',
      payload: e.target.innerText,
    });

    onNext();
  };

  return <ButtonList options={options} onClick={handleClick} />;
};

MileageStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default MileageStep;
