import React from 'react';
import PropTypes from 'prop-types';
import { useElasticSearch } from './ElasticSearch';
import ToggleCheckbox from '../ui/ToggleCheckbox';

const OVOToggle = ({ isDesktopVersion }) => {
  const elasticSearch = useElasticSearch();
  return (
    <div style={{ width: '232px', flexShrink: 0, marginTop: '3px' }}>
      <ToggleCheckbox
        name="promotion_name"
        value="OVO Promotion"
        greenToggle
        isDesktopVersion={isDesktopVersion}
        label="Free EV miles offer"
        labelPosition="left"
        defaultChecked={
          !!elasticSearch.props.selectedFilter && elasticSearch.props.selectedFilters.promotion_name === 'OVO Promotion'
        }
        selectedFilters={elasticSearch.props.selectedFilters}
        setSingleCheckboxFilter={elasticSearch.props.setSingleCheckboxFilter}
      />
    </div>
  );
};

OVOToggle.propTypes = {
  isDesktopVersion: PropTypes.bool.isRequired,
};

export default OVOToggle;
