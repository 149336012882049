import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';

import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const ContractLength = ({ name, options, isDesktop, elasticSearchProps }) => {
  options = options.map((option) => {
    const monthLabel = option.value === '60' ? 'months (Finance Lease Only)' : 'months';
    const label = `${option.value} ${monthLabel}`;

    return { ...option, label };
  });

  options.sort((a, b) => a.value - b.value);

  const selected =
    elasticSearchProps.selectedFilters[name] &&
    options.find((option) => option.value === elasticSearchProps.selectedFilters[name]);
  const count = selected ? selected.label : '';

  return (
    <FilterDrawerItem
      title="Contract length"
      allText="Any"
      count={count}
      name={name}
      options={options}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

ContractLength.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

ContractLength.defaultProps = {
  options: [],
  isDesktop: false,
};

export default ContractLength;
