import React, { useEffect, useMemo } from 'react';
import StepZilla from 'react-stepzilla';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

// Component Imports
import StepTrackerPage from './StepTrackerPage';
import TypeStep from './steps/TypeStep';
import TermStep from './steps/TermStep';
import MileageStep from './steps/MileageStep';
import BudgetStep from './steps/BudgetStep';
import FuelStep from './steps/FuelStep';
import TransmissionStep from './steps/TransmissionStep';
import BodyTypeStep from './steps/BodyTypeStep';

// Style Imports
import './StepTracker.scss';

// Helpers
import { getQueryStringParameters } from '../../utils';
import { renameTypeKeys } from './utils';

const StepTracker = () => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const newTypeKeys = { 'display_fueltype[]': 'display_fueltype', 'bodytype_categories[]': 'bodytype_categories' };
  const url_query = renameTypeKeys(getQueryStringParameters(), newTypeKeys);
  const url_query_exists = Object.keys(url_query).length !== 0;

  const filterData = (data) => {
    const filteredTypes = {
      fuelTypes: [],
      transmissionTypes: [],
      bodyTypes: [],
    };

    data.forEach((item) => {
      if (!filteredTypes.fuelTypes.includes(item.display_fueltype)) {
        filteredTypes.fuelTypes.push(item.display_fueltype);
      }

      if (!filteredTypes.transmissionTypes.includes(item.transmission)) {
        filteredTypes.transmissionTypes.push(item.transmission);
      }

      if (!filteredTypes.bodyTypes.includes(item.bodytype_categories)) {
        filteredTypes.bodyTypes.push(item.bodytype_categories);
      }
    });

    dispatch({ type: 'SET_FILTERED_FUELTYPES', payload: filteredTypes.fuelTypes });
    dispatch({ type: 'SET_FILTERED_TRANSMISSION', payload: filteredTypes.transmissionTypes });
    dispatch({ type: 'SET_FILTERED_BODYTYPE', payload: filteredTypes.bodyTypes });
  };

  const getQueryData = (queryParams) => {
    axios({
      url: 'find-your-perfect-deal',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      params: queryParams,
    }).then((response) => {
      filterData(response.data.results);
    });
  };

  const resetReduxState = () => {
    if (url_query_exists) getQueryData(url_query);
    dispatch({ type: 'RESET_FMPC_STATE', payload: url_query });
  };

  useEffect(() => {
    document.addEventListener('turbolinks:load', resetReduxState());

    return () => {
      document.removeEventListener('turbolinks:load', resetReduxState());
    };
  }, []);

  useEffect(() => {
    if (url_query_exists) {
      setTimeout(() => {
        const cardsContainer = document.querySelector('.g-perfect-deals');
        cardsContainer.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 500);
    }
  }, []);

  const termString = `${query.term}${['Term', 'Any'].includes(query.term) ? '' : ' months'}`;
  const mileageString = `${query.mileage}${query.mileage !== 'Mileage' ? ' miles' : ''}`;

  const stepsArray = [];

  const propSteps = [
    {
      name: query.type,
      heading: 'What type of customer are you?',
      component: <TypeStep />,
      nextDisabled: true,
    },
    {
      name: termString,
      heading: 'How many months do you want the car for?',
      component: <TermStep />,
      nextDisabled: true,
    },
    {
      name: mileageString,
      heading: 'Approximately, how many miles do you expect to drive every year?',
      component: <MileageStep />,
      nextDisabled: true,
    },
    { name: query.budget, heading: 'How much would you like to pay per month?', component: <BudgetStep /> },
    {
      name: query.fuel,
      heading: 'Which fuel type are you interested in?',
      component: <FuelStep />,
      nextDisabled: true,
    },
    {
      name: query.transmission,
      heading: 'Which gearbox are you interested in?',
      component: <TransmissionStep />,
      nextDisabled: true,
    },
    { name: 'Body type', heading: 'Which body type(s) are you interested in?', component: <BodyTypeStep /> },
  ];

  for (let i = 0; i < propSteps.length; i += 1) {
    const step = {
      name: propSteps[i].name,
      component: (
        <StepTrackerPage
          step={i}
          component={propSteps[i].component}
          nextDisabled={propSteps[i].nextDisabled}
          backDisabled={propSteps[i].backDisabled}
          isLast={i === propSteps.length - 1}
          heading={propSteps[i].heading}
          pages={propSteps}
        />
      ),
    };

    stepsArray.push(step);
  }

  const stepTrackerPositionClassName = useMemo(() => {
    if (query.currentStep === 6) return 'step-tracker--second-last';
    if (query.currentStep === 7) return 'step-tracker--last';
    return '';
  }, [query.currentStep]);

  return (
    <div className={`step-tracker ${stepTrackerPositionClassName}`}>
      <p className="step-tracker__numbering">
        Step
        <span>
          <em>{query.currentStep}</em>
        </span>{' '}
        of
        <span>
          <em>{propSteps.length}</em>
        </span>
      </p>

      <StepZilla
        showSteps={false}
        steps={stepsArray}
        stepsNavigation={false}
        showNavigation={false}
        startAtStep={url_query_exists ? 6 : 0}
      />
    </div>
  );
};

export default StepTracker;
