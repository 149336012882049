import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import StepTrackerControls from './StepTrackerControls';
import StepTrackerPagination from './StepTrackerPagination';

const StepTrackerPage = ({ heading, pages, jumpToStep, step, isLast, component, nextDisabled, backDisabled }) => {
  const [animateNext, setAnimateNext] = useState(false);
  const [animateBack, setAnimateBack] = useState(false);

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.findYourPerfectCar);

  const nextClicked = () => {
    jumpToStep(step + 1);

    dispatch({
      type: 'SET_FMPC_CURRENT_STEP',
      payload: isLast ? query.currentStep : query.currentStep + 1,
    });

    // Handle card animation
    setAnimateNext(true);
    // Wait for end of animation & reset
    setTimeout(() => {
      setAnimateNext(false);
    }, 350);
  };

  const backClicked = () => {
    jumpToStep(step - 1);

    dispatch({
      type: 'SET_FMPC_CURRENT_STEP',
      payload: query.currentStep - 1,
    });

    // Handle card animation
    setAnimateBack(true);
    // Wait for end of animation & reset
    setTimeout(() => {
      setAnimateBack(false);
    }, 350);
  };

  const stepTrackerDirectionClassName = useMemo(() => {
    if (animateNext) return 'step-tracker__accent--next';
    if (animateBack) return 'step-tracker__accent--back';
    return '';
  }, [animateNext, animateBack]);

  return (
    <>
      <StepTrackerPagination pages={pages} jumpToStep={jumpToStep} />

      <div className="step-tracker__content">
        <div className="step-tracker__step">
          <h4 className="step-tracker__heading">{heading}</h4>

          {React.cloneElement(component, {
            onNext: nextClicked,
            onBack: backClicked,
          })}
        </div>

        <StepTrackerControls
          isLast={isLast}
          step={step}
          backDisabled={backDisabled}
          nextDisabled={nextDisabled}
          nextClicked={nextClicked}
          backClicked={backClicked}
        />
      </div>
      <span className={`step-tracker__accent ${stepTrackerDirectionClassName}`} />
    </>
  );
};

StepTrackerPage.propTypes = {
  heading: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  jumpToStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  isLast: PropTypes.bool,
  component: PropTypes.node.isRequired,
  nextDisabled: PropTypes.bool,
  backDisabled: PropTypes.bool,
};

StepTrackerPage.defaultProps = {
  pages: [],
  isLast: false,
  nextDisabled: false,
  backDisabled: false,
};
export default StepTrackerPage;
